import { AddressAutocomplete } from '@ifca-root/react-component/src/components/Input/AddressAutocomplete'
import { TextField } from '@material-ui/core'
import { createFilterOptions } from '@material-ui/lab'
import React from 'react'

// 'AIzaSyBbPkG0hX9GDe3aAB5LxaQkPnuQ7KGDViI'

export const AddressAutoComponent = (props: any) => {
  const {
    getCountry, // country
    getStatebyCountry, // State
    getCitybyState, // City
    getPostcodebyCity, // Postcode
    handleCountryOnChange,
    handleStateOnChange,
    handleCityOnChange,
    handlePostCodeOnChange,
    handleGoogleOnChange,
    autoCompleteRef,
    state,
    formType = 'add', // default to 'add' to allow for editable fields (all)
    country,
    states,
    city,
    postcode,
    setCountry,
    setStates,
    setCity,
    setPostcode,
    isMandatory = true,
    isSubmitted,
  } = props

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: any) => option.name,
  })

  return (
    <AddressAutocomplete
      sections={{
        address: {
          props: {
            inputRef: autoCompleteRef,
            label: isMandatory ? 'Address *' : 'Address',
            // value: query,
            value: state.address,
            multiline: true,
            onChange: e => handleGoogleOnChange(e.target.value),
            style: { width: '100%' },
            error: isMandatory && !state.address && isSubmitted ? true : false,
            helperText:
              isMandatory && !state.address && isSubmitted
                ? 'Address is required'
                : '',
          },
        },
        country: {
          props: {
            value: state.country,
            options: getCountry || [],
            style: { width: '100%' },
            filterOptions: filterOptions,
            onChange: (e, newValue) => {
              handleCountryOnChange(newValue)
            },
            inputValue: country,
            onInputChange: (e, value) => {
              if (setCountry) {
                setCountry(value)
                handleCountryOnChange({ name: value })
              }
            },
            getOptionLabel: (option: any) => option.name || '',
            renderInput: params => {
              if (formType === 'edit') {
                // anyone changing this astronomically weird shit, this is the key
                // to it all when editing because apparently setting default value
                // doesnt fucking work
                params.inputProps.value = country
              }

              return (
                <TextField
                  {...params}
                  value={state.country.name}
                  label="Country"
                />
              )
            },
          },
        },
        state: {
          props: {
            value: state.state,
            options: getStatebyCountry || [],
            style: { width: '100%' },
            getOptionLabel: option => option.name || '',
            onChange: (e, newValue) => {
              if (newValue) {
                handleStateOnChange(newValue || { name: e.target.value })
              }
            },
            inputValue: states,
            onInputChange: (e, newValue) => {
              if (setStates) {
                setStates(newValue)
                handleStateOnChange({ name: newValue })
              }
            },
            renderInput: params => {
              if (formType === 'edit') {
                params.inputProps.value = state.state.name
              }
              return (
                <TextField
                  {...params}
                  value={state.state || ''}
                  label="State"
                />
              )
            },
          },
        },
        city: {
          props: {
            value: state.city,
            options: getCitybyState || [],
            style: { width: '50%' },
            getOptionLabel: option => option.name || '',
            onChange: (e, newValue) => {
              if (newValue) {
                handleCityOnChange(newValue || { name: e.target.value })
              }
            },
            inputValue: city,
            // onInputChange: (e, newValue) => {
            //   if (!!setCity) setCity(newValue)
            //   else handleCityOnChange({ name: newValue })
            // },
            onInputChange: (e, newValue) => {
              if (setCity) {
                setCity(newValue)
              }

              handleCityOnChange({ name: newValue })
            },
            renderInput: params => {
              if (formType === 'edit') {
                params.inputProps.value = state.city.name
              }
              return (
                <TextField
                  {...params}
                  value={state.city || ''}
                  label="City"
                  style={{ width: '100%' }}
                />
              )
            },
          },
        },
        postCode: {
          props: {
            value: state.postcode,
            options: getPostcodebyCity || [],
            style: { width: '50%' },
            onChange: (e: any, newValue) => {
              if (!!newValue) {
                handlePostCodeOnChange(newValue || { postcode: e.target.value })
              }
            },
            // onInputChange: (e, newValue) => {
            //   if (!!setPostcode) setPostcode(newValue)
            //   else handlePostCodeOnChange({ name: newValue })
            // },

            onInputChange: (e, newValue) => {
              if (setPostcode) {
                setPostcode(newValue)
              }

              handlePostCodeOnChange({ postcode: newValue })
            },
            getOptionLabel: option => option.postcode || '',
            renderInput: params => {
              if (formType === 'edit') {
                params.inputProps.value = state.postcode.postcode
              }

              return (
                <TextField
                  {...params}
                  value={state.postcode || ''}
                  style={{ width: '100%', paddingLeft: '4px' }}
                  label="Postcode"
                />
              )
            },
          },
        },
      }}
    />
  )
}
