import { yupResolver } from '@hookform/resolvers'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { ItemVoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomItemTextField'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Grid, ListSubheader, MenuItem, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import BigNumber from 'bignumber.js'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import {
  RecordStatus,
  useGetClassificationCodeQuery,
  useGetTaxSchemeQuery,
  useGetDepartmentListingByItemIdLazyQuery,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import {
  amtFloat,
  amtNumStr,
  amtStr,
} from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import * as yup from 'yup'
import { calculateDocAmt } from '../../ARAPModule/Helper/AmountCalculation'

interface ItemProps {
  BillItemID: string
  Amount: number
  DocAmt: number
  TaxSchemeID: string
  TaxAmt: number
  TaxRate: number
  CostCentreID: string
  Remark: string
  einvoice_classification_code_id: string
}

const defaultVal: any = {
  BillItemID: null,
  Amount: 0,
  DocAmt: 0,
  TaxSchemeID: null,
  TaxAmt: 0,
  TaxRate: 0,
  CostCentreID: null,
  Remark: '',
  einvoice_classification_code_id: '',
}

export const ARItemDialog = (props: any) => {
  const {
    CompanyID,
    openItemDialog,
    setOpenItemDialog,
    itemData,
    BillItemLoading,
    fetchBillItem,
    getBillItem,
    title,
    watch,
    menu,
    selectedTax,
    setSelectedTax,
    primaryItemKey,
  } = props

  // ================================== //
  // ============ USESTATE ============ //
  // ================================== //
  const [eInvoiceItemClassification, setEInvoiceItemClassification] = useState(
    null
  )
  const [classificationCodeID, setClassificationCodeID] = useState(
    menu?.obj?.einvoice_classification_code_id
      ? menu?.obj?.einvoice_classification_code_id
      : null
  )

  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [itemRecord, setItemRecord] = useState(false)
  const [itemVoiceRemark, setItemVoiceRemark] = useState('')

  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const [cost, setCost] = useState(
    menu?.obj ? menu?.obj?.DocAmt - menu?.obj?.TaxAmt : 0
  )
  const [taxAmt, setTaxAmt] = useState(
    !!menu?.obj?.TaxAmt ? menu?.obj?.TaxAmt : 0
  )
  const docAmt = calculateDocAmt(cost, taxAmt)
  const ItemSchema = yup.object().shape({
    TaxSchemeID: CommonYupValidation.requireField('Tax Code is required'),
    BillItemID: CommonYupValidation.requireField('Item is required'),
    CostCentreID: CommonYupValidation.requireField('Department is required'),
    Amount: yup
      .string()
      .required('Amount is required')
      .test('sumZero', 'Amount should not be zero', val => {
        return parseFloat(amtNumStr(val)) !== 0
      }),
    Remark: CommonYupValidation.requireField('Remark is required'),
  })

  const [mountDefVal, setMountDefVal] = useState(!!menu?.obj)

  // ================================= //
  // ============ USEFORM ============ //
  // ================================= //
  const {
    handleSubmit: handleItemSubmit,
    register: itemRegister,
    setValue: setValueItem,
    watch: itemWatch,
    errors: itemErrors,
    control: itemControl,
    clearErrors: clearErrorsItem,
    reset: itemReset,
  } = useForm<ItemProps>({
    defaultValues: {
      BillItemID: !!menu?.obj?.BillItemID ? menu?.obj?.BillItemID : '',
      Amount: !!menu?.obj?.Amount ? menu?.obj?.Amount : 0,
      DocAmt: !!menu?.obj?.DocAmt ? menu?.obj?.DocAmt : 0,
      TaxSchemeID: !!menu?.obj?.TaxSchemeID ? menu?.obj?.TaxSchemeID : '',
      TaxAmt: !!menu?.obj?.TaxAmt ? menu?.obj?.TaxAmt : 0,
      TaxRate: 0,
      Remark: !!menu?.obj?.Remark ? menu?.obj?.Remark : '',
      einvoice_classification_code_id: !!menu?.obj
        ?.einvoice_classification_code_id
        ? menu?.obj?.einvoice_classification_code_id
        : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(ItemSchema),
  })

  // ================================= //
  // ============ QUERIES ============ //
  // ================================= //
  const {
    loading: TaxSchemeLoading,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    variables: { RecordStatus: RecordStatus.Active },
    onCompleted: () => {
      if (!!menu?.obj) {
        setSelectedTax(
          taxScheme?.find(x => x?.TaxSchemeID === menu?.obj?.TaxSchemeID)
        )
      }
      if (menu?.obj?.BillItemID && mountDefVal === true) {
        fetchDepartmentListingByItemID({
          variables: {
            CompanyID,
            BillItemID: menu?.obj?.BillItemID,
          },
        })
      }
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const {
    loading: ClassificationCodeLoading,
    data: { getClassificationCode } = { getClassificationCode: [] },
  } = useGetClassificationCodeQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    fetchDepartmentListingByItemID,
    {
      loading: HandlerLoading,
      data: { getDepartmentListingByItemID } = {
        getDepartmentListingByItemID: [],
      },
    },
  ] = useGetDepartmentListingByItemIdLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getDepartmentListingByItemID }) => {
      if (
        getDepartmentListingByItemID?.length === 1 &&
        !!getDepartmentListingByItemID[0]?.IsDefault
      ) {
        setValueItem(
          'CostCentreID',
          getDepartmentListingByItemID[0]?.CostCentreID
        )
      }
      if (!!menu?.obj?.CostCentreID && mountDefVal === true) {
        setValueItem('CostCentreID', menu?.obj?.CostCentreID)
      }
    },
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(`${errorMessage}"`)
    },
  })

  const taxScheme: any = getTaxScheme
  // To get list of Tax Code that has been effective //
  const taxEffectiveDate: any = new Date(
    new Date().getTime() + 24 * 60 * 60 * 1000
  )
    .toISOString()
    .slice(0, 10)

  const taxSchemeInput: any = getTaxScheme?.filter(
    el =>
      el?.AccTaxClass === 'INPUT' &&
      el?.TaxEffective.map(y => y?.Date) <= taxEffectiveDate
  )

  const taxSchemeOutput: any = getTaxScheme?.filter(
    el =>
      el?.AccTaxClass === 'OUTPUT' &&
      el?.TaxEffective.map(y => y?.Date) <= taxEffectiveDate
  )

  const taxCodeName = getTaxScheme?.filter(
    x => x?.TaxSchemeID === selectedTax?.TaxSchemeID
  )[0]?.Code

  const taxCodeRate = amtStr(
    getTaxScheme?.filter(x => x?.TaxSchemeID === selectedTax?.TaxSchemeID)[0]
      ?.LatestTax?.Rate
  )

  const itemName =
    itemWatch('BillItemID') !== undefined
      ? getBillItem.find(x => x?.BillItemID === itemWatch('BillItemID'))?.Name
      : getBillItem.find(x => x?.BillItemID === menu?.obj?.BillItemID)?.Name

  const classificationCode = !!classificationCodeID
    ? getClassificationCode?.find(x => x?.id === classificationCodeID)
        ?.classificationCode
    : getClassificationCode?.find(
        x => x?.id === menu?.obj?.einvoice_classification_code_id
      )?.classificationCode

  const classificationDescription = !!classificationCodeID
    ? getClassificationCode?.find(x => x?.id === classificationCodeID)
        ?.description
    : getClassificationCode?.find(
        x => x?.id === menu?.obj?.einvoice_classification_code_id
      )?.description

  const onSubmit = (data, docAmt, index, isSaveNew) => {
    if (!menu?.obj) {
      itemData.push({
        Sequence: itemData?.length + 1,
        BillItemID: data?.BillItemID,
        CostCentreID: data?.CostCentreID,
        Amount: parseFloat(data?.Amount.replace(/,/g, '')),
        TaxSchemeID: data?.TaxSchemeID,
        TaxCode: taxCodeName,
        TaxRate: taxCodeRate,
        BillItemName: itemName,
        CostCentreName: getDepartmentListingByItemID?.find(
          x => x?.CostCentreID === itemWatch('CostCentreID')
        )?.Code,
        TaxAmt: data?.TaxAmt,
        DocAmt: docAmt,
        Remark: data?.Remark,
        einvoice_classification_code_id:
          classificationCodeID !== null ? classificationCodeID : null,
        ClassificationCode:
          classificationCodeID !== null ? classificationCode : null,
        ClassificationDescription:
          classificationCodeID !== null ? classificationDescription : null,
      })
    } else {
      let existingItemData = itemData[index]

      itemData[index] = {
        Sequence: itemData?.Sequence ?? index + 1,
        [`${primaryItemKey}`]: itemData[index]?.[`${primaryItemKey}`],
        BillItemID: data?.BillItemID,
        CostCentreID: data?.CostCentreID,
        Amount: data?.Amount,
        BillItemName: itemName,
        CostCentreName: getDepartmentListingByItemID?.find(
          x => x?.CostCentreID === itemWatch('CostCentreID')
        )?.Code,
        TaxRate: taxCodeRate ?? existingItemData?.TaxRate,
        TaxCode: taxCodeName,
        TaxSchemeID: data?.TaxSchemeID,
        // TaxAmt: !!data?.TaxAmt ? data?.TaxAmt : existingItemData?.TaxAmt,
        TaxAmt: data?.TaxAmt,
        DocAmt: docAmt,
        Remark: data?.Remark,
        einvoice_classification_code_id:
          classificationCodeID !== null ? classificationCodeID : null,
        ClassificationCode:
          classificationCodeID !== null ? classificationCode : null,
        ClassificationDescription:
          classificationCodeID !== null ? classificationDescription : null,
      }
    }

    if (!isSaveNew) {
      setOpenItemDialog(false)
    } else {
      fetchBillItem()
      itemReset(defaultVal)
      setOpenItemDialog(true)
    }
  }

  const disableDepartmentController: () => boolean = () => {
    return !!(
      getDepartmentListingByItemID?.length === 1 &&
      getDepartmentListingByItemID[0]?.IsDefault === true
    )
  }

  const handleAmtChange = event => {
    setCost(event.value)
    if (selectedTax) {
      setTaxAmt(
        new BigNumber(
          selectedTax?.LatestTax === null
            ? selectedTax?.TaxEffective?.reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
            : selectedTax?.LatestTax?.Rate
        )
          .dividedBy(100)
          .multipliedBy(Number(event.value))
          .toNumber()
      )
      setValueItem('TaxAmt', taxAmt)
    }
  }

  const handleTaxChange = (event, taxEffectiveDate) => {
    setSelectedTax(taxScheme.find(x => x?.TaxSchemeID === event?.target?.value))
    setTaxAmt(
      (new BigNumber(
        taxScheme.filter(x => x?.TaxSchemeID === event?.target?.value)[0]
          .LatestTax === null
          ? taxScheme
              .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
              ?.TaxEffective.reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
          : taxScheme
              .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
              ?.TaxEffective?.filter(
                x => new Date(x?.Date) <= new Date(taxEffectiveDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
      ).toNumber() /
        100) *
        cost
    )
  }

  const handleTaxAmtChange = event => {
    setTaxAmt(event.value)
  }

  const checkClassificationCode = billItem => {
    if (
      billItem?.einvoice_classification_code_id !== null &&
      billItem?.einvoice_classification_code_id !== undefined &&
      billItem?.einvoice_classification_code_id !== ''
    )
      return true
    else return false
  }

  //========USE EFFECT========//
  // Autoset tax after select tax amount
  useEffect(() => {
    if (!!selectedTax) {
      setValueItem('TaxSchemeID', selectedTax?.TaxSchemeID)
      clearErrorsItem('TaxSchemeID')
    }
  }, [selectedTax])

  useEffect(() => {
    if (menu?.obj) {
      if (menu?.obj?.einvoice_classification_code_id !== undefined) {
        setValueItem(
          'einvoice_classification_code_id',
          menu?.obj?.einvoice_classification_code_id
        )
      }
    }
  }, [menu?.obj])

  useEffect(() => {
    if (!isNaN(taxAmt)) {
      setValueItem('TaxAmt', taxAmt)
      clearErrorsItem('TaxAmt')
    }
  }, [taxAmt])

  useEffect(() => {
    if (!!menu?.obj?.TaxSchemeID) {
      let selectTax = taxScheme.find(
        x => x?.TaxSchemeID === menu?.obj?.TaxSchemeID
      )
      setSelectedTax(selectTax)
    }
  }, [menu?.obj])

  return (
    <>
      {HandlerLoading && <Loading />}
      {BillItemLoading && <Loading />}
      {TaxSchemeLoading && <Loading />}
      {ClassificationCodeLoading && <Loading />}
      <CommonDialog
        fullWidth={true}
        open={openItemDialog}
        onClose={() => {
          setOpenItemDialog(false)
        }}
        sections={{
          header: { title, rightText: !!menu?.obj ? 'Edit' : 'New' },
          body: () => (
            <>
              <div className="content-container" style={{ marginTop: '-20px' }}>
                {!BillItemLoading && (
                  <Controller
                    render={({ onChange, value }) => {
                      const defVal = getBillItem?.filter(
                        x => x?.BillItemID === menu?.obj?.BillItemID
                      )[0]

                      return (
                        <Autocomplete
                          options={
                            watch('is_einvoice') === true
                              ? getBillItem.filter(
                                  item => item.einv_integrated === true
                                )
                              : getBillItem?.sort((a, b) => {
                                  return a.Name.localeCompare(b.Name)
                                }) || []
                          }
                          getOptionLabel={option =>
                            `${option?.Name} | ${option?.Description}`
                          }
                          fullWidth
                          onChange={(value, newValue: any, reason) => {
                            onChange(newValue?.BillItemID)
                            setValueItem('BillItemID', newValue?.BillItemID)
                            setValueItem('CostCentreID', null)
                            setMountDefVal(false)
                            fetchDepartmentListingByItemID({
                              variables: {
                                CompanyID,
                                BillItemID: newValue?.BillItemID,
                              },
                            })
                            if (!!checkClassificationCode(newValue)) {
                              setClassificationCodeID(
                                !!newValue?.einvoice_classification_code_id
                                  ? newValue?.einvoice_classification_code_id
                                  : null
                              )
                              if (
                                newValue?.einvoice_classification_code_id !==
                                null
                              ) {
                                setEInvoiceItemClassification(
                                  getClassificationCode?.filter(
                                    x =>
                                      x?.id ===
                                      newValue?.einvoice_classification_code_id
                                  )[0].classificationCode +
                                    ` (` +
                                    getClassificationCode?.filter(
                                      x =>
                                        x?.id ===
                                        newValue?.einvoice_classification_code_id
                                    )[0].description +
                                    `)`
                                )
                                setValueItem(
                                  'einvoice_classification_code_id',
                                  classificationCodeID ??
                                    newValue?.einvoice_classification_code_id
                                )
                              }
                            }
                          }}
                          renderOption={(props, option) => {
                            return (
                              <div>
                                <div>
                                  <span className="xsTitle">{props?.Name}</span>
                                </div>
                                <div className="desc">{props?.Description}</div>
                              </div>
                            )
                          }}
                          defaultValue={defVal}
                          renderInput={(params: any) => {
                            return (
                              <div>
                                <TextField
                                  {...params}
                                  required
                                  label="Item"
                                  variant="standard"
                                  fullWidth
                                  name="BillItemID"
                                  helperText={itemErrors?.BillItemID?.message}
                                  error={itemErrors?.BillItemID ? true : false}
                                />
                              </div>
                            )
                          }}
                        />
                      )
                    }}
                    name="BillItemID"
                    autoComplete="off"
                    control={itemControl}
                    multiline={true}
                    fullWidth
                    margin="dense"
                    ref={itemRegister}
                    required
                    defaultValue={menu?.obj?.BillItemID}
                    helperText={itemErrors?.BillItemID?.message}
                    error={itemErrors?.BillItemID ? true : false}
                  />
                )}

                {/* Classification e-Invoice */}
                {watch('is_einvoice') === true && !ClassificationCodeLoading && (
                  <Controller
                    render={({ value, onChange }) => {
                      return (
                        <TextField
                          label="Classification"
                          value={
                            menu?.obj?.einvoice_classification_code_id
                              ? `${getClassificationCode?.filter(
                                  x =>
                                    x?.id ===
                                    menu?.obj?.einvoice_classification_code_id
                                )[0].classificationCode +
                                  ` (` +
                                  getClassificationCode?.filter(
                                    x =>
                                      x?.id ===
                                      menu?.obj?.einvoice_classification_code_id
                                  )[0].description +
                                  `)`}`
                              : eInvoiceItemClassification ?? ''
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          disabled
                          name="einvoice_classification_code_id"
                        />
                      )
                    }}
                    margin="dense"
                    name="einvoice_classification_code_id"
                    ref={itemRegister}
                    control={itemControl}
                    defaultValue={
                      menu?.obj?.einvoice_classification_code_id
                        ? menu?.obj?.einvoice_classification_code_id
                        : null
                    }
                    disabled
                  />
                )}

                <Controller
                  render={({ value, onChange }) => {
                    const defVal = !!itemWatch('CostCentreID')
                      ? getDepartmentListingByItemID?.find(
                          x => x?.CostCentreID === itemWatch('CostCentreID')
                        )
                      : null
                    return (
                      <Autocomplete
                        options={getDepartmentListingByItemID || []}
                        getOptionLabel={option =>
                          option ? `${option?.Code} | ${option?.Name}` : ''
                        }
                        fullWidth
                        onChange={(value, newValue: any) => {
                          onChange(newValue?.CostCentreID)
                        }}
                        disableClearable={disableDepartmentController()}
                        disabled={disableDepartmentController()}
                        renderOption={(props, option) => {
                          return (
                            <div>
                              <div>
                                <span className="xsTitle">{props?.Code}</span>
                              </div>
                              <div className="desc">{props?.Name}</div>
                            </div>
                          )
                        }}
                        value={
                          disableDepartmentController() === true
                            ? getDepartmentListingByItemID[0]
                            : defVal
                        }
                        renderInput={(params: any) => {
                          return (
                            <div>
                              <TextField
                                {...params}
                                helperText={
                                  itemErrors?.CostCentreID
                                    ? 'Department is required'
                                    : ''
                                }
                                error={itemErrors?.CostCentreID ? true : false}
                                label="Department"
                                style={{ width: '100%' }}
                                margin="dense"
                                disabled={disableDepartmentController()}
                                required
                              />
                            </div>
                          )
                        }}
                      />
                    )
                  }}
                  label="Department"
                  name="CostCentreID"
                  autoComplete="off"
                  control={itemControl}
                  multiline={true}
                  fullWidth
                  margin="dense"
                  ref={itemRegister}
                  helperText={itemErrors?.CostCentreID?.message}
                  error={itemErrors?.CostCentreID ? true : false}
                  disabled={disableDepartmentController()}
                  required
                />

                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  thousandSeparator
                  customInput={TextField}
                  id="standard-basic"
                  name="Amount"
                  label="Amount"
                  value={cost}
                  autoComplete="off"
                  control={itemControl}
                  onValueChange={e => {
                    handleAmtChange(e)
                  }}
                  decimalScale={2}
                  fixedDecimalScale
                  margin="dense"
                  required
                  fullWidth
                  helperText={itemErrors?.Amount?.message}
                  error={itemErrors?.Amount ? true : false}
                  ref={itemRegister()}
                  defaultValue={menu?.obj?.Amount || ''}
                />
                <Grid
                  item
                  xs={6}
                  style={{
                    marginBottom: '5px',
                    paddingRight: '10px',
                  }}
                >
                  {!TaxSchemeLoading && (
                    <Controller
                      as={
                        <TextField
                          margin="dense"
                          label="Tax Code"
                          required
                          SelectProps={{
                            renderValue: () => `${selectedTax?.Code}`,
                            onChange: event => {
                              handleTaxChange(event, taxEffectiveDate)
                              setValueItem(
                                'TaxSchemeID',
                                event?.target?.value.toString()
                              )
                              clearErrorsItem('TaxSchemeID')
                            },
                          }}
                          select
                          helperText={itemErrors?.TaxSchemeID?.message}
                          error={itemErrors?.TaxSchemeID ? true : false}
                        >
                          <ListSubheader>Input</ListSubheader>
                          {taxSchemeInput?.map((tax, index) => (
                            <MenuItem
                              id="tax-select"
                              key={index}
                              value={tax?.TaxSchemeID}
                            >
                              <span className="text-noflow">{`${tax?.Code}`}</span>
                            </MenuItem>
                          ))}
                          <ListSubheader>Output</ListSubheader>
                          {taxSchemeOutput?.map((tax, index) => (
                            <MenuItem
                              id="tax-select"
                              key={index}
                              value={tax?.TaxSchemeID}
                            >
                              <span className="text-noflow">{`${tax?.Code}`}</span>
                            </MenuItem>
                          ))}
                        </TextField>
                      }
                      select
                      name="TaxSchemeID"
                      autoComplete="off"
                      control={itemControl}
                      defaultValue={menu?.obj?.TaxSchemeID || ''}
                      ref={itemRegister}
                    />
                  )}
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    marginBottom: '5px',
                  }}
                >
                  <TextField
                    name="TaxRate"
                    label="Tax Rate %"
                    value={amtStr(taxCodeRate)}
                    disabled
                    margin="normal"
                    style={{ marginTop: '5px' }}
                  />
                </Grid>

                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  thousandSeparator
                  customInput={TextField}
                  ref={itemRegister()}
                  control={itemControl}
                  margin="dense"
                  name="TaxAmt"
                  label="Tax Amount"
                  onValueChange={e => {
                    handleTaxAmtChange(e?.floatValue)
                    setTaxAmt(e?.floatValue)
                  }}
                  value={taxAmt}
                  defaultValue={menu?.obj?.TaxAmt ?? taxAmt ?? 0}
                  decimalScale={2}
                  fixedDecimalScale
                  className="left"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                <Controller
                  render={({ value, onChange }) => {
                    value = { docAmt }
                    return (
                      <TextField
                        label="Doc Amount"
                        value={amtStr(docAmt)}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        className="right"
                        disabled
                      />
                    )
                  }}
                  disabled
                  margin="dense"
                  defaultValue={(docAmt || 0) ?? (menu?.obj?.DocAmt || 0)}
                  name="DocAmt"
                  ref={itemRegister()}
                  control={itemControl}
                />
                <ItemVoiceTextField
                  mounted={true}
                  label="Remark"
                  name="Remark"
                  value={itemVoiceRemark}
                  setValue={setValueItem}
                  record={itemRecord}
                  setRecord={setItemRecord}
                  control={itemControl}
                  controllerProps={{
                    error: itemErrors?.Remark ? true : false,
                    defaultValue: menu?.obj?.Remark,
                    helperText: itemErrors?.Remark?.message,
                    ref: itemRegister,
                    autoComplete: 'off',
                  }}
                  required
                />
              </div>
            </>
          ),
          footer: {
            actions: !menu?.obj
              ? [
                  {
                    displayText: 'Close',
                    props: {
                      onClick: () => {
                        setOpenItemDialog(false)
                      },
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                  {
                    displayText: 'Confirm & New',
                    props: {
                      onClick: () => {
                        handleItemSubmit(data =>
                          onSubmit(data, docAmt, menu?.index, true)
                        )()
                        setMountDefVal(false)
                      },
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                  {
                    displayText: 'Confirm',
                    props: {
                      onClick: () => {
                        handleItemSubmit(data =>
                          onSubmit(data, docAmt, menu?.index, false)
                        )()
                      },
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                ]
              : [
                  {
                    displayText: 'Close',
                    props: {
                      onClick: () => setOpenItemDialog(false),
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                  {
                    displayText: 'Confirm',
                    props: {
                      onClick: () => {
                        handleItemSubmit(data =>
                          onSubmit(data, docAmt, menu?.index, false)
                        )()
                      },
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                ],
          },
        }}
      />
      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
