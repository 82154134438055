import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { List, ListItem, ListItemText } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import {
  AcctPermission,
  useGetCreditorAccountQuery,
  useGetCreditorTypeLazyQuery,
  useGetTaxSchemeQuery,
} from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { lowerCaseAllWordsExceptFirstLetters } from 'helpers/stringConverter'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const CreditorProfileDetail = (props: any) => {
  const { page }: any = props
  let history = useHistory()
  const { creditorType, CreditorAccountID }: any = useParams()
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  const eInvoiceSubscriber = JSON.parse(
    localStorage.getItem('eInvoiceSubscriber')
  )

  let creditorName = type => {
    let temp
    switch (type) {
      case 'supplier':
        temp = 'Supplier'
        break
      case 'contractor':
        temp = 'Contractor'
        break
      case 'consultant':
        temp = 'Consultant'
        break
      case 'agent':
        temp = 'Agent'
        break
    }
    return temp
  }

  const {
    loading: CreditorAccountLoading,
    error: CreditorAccountError,
    data: { getCreditorAccount } = { getCreditorAccount: [] },
  } = useGetCreditorAccountQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    variables: { CreditorAccountID: CreditorAccountID },
    onCompleted: ({ getCreditorAccount }) => {
      if (getCreditorAccount?.length > 0) {
        fetchCreditorType({
          variables: {
            CreditorTypeID: getCreditorAccount[0]?.CreditorTypeID,
          },
        })
      }
    },
  })

  const [
    fetchCreditorType,
    {
      loading: CreditorTypeLoading,
      error: CreditorTypeError,
      data: { getCreditorType } = { getCreditorType: [] },
    },
  ] = useGetCreditorTypeLazyQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: TaxLoading,
    error: TaxError,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    fetchPolicy: 'network-only',
  })

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  return (
    <>
      {TaxLoading && <Loading />}
      {CreditorTypeLoading && <Loading />}
      {CreditorAccountLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          page === 'creditor-profile'
            ? history.push(
                `/account-payable/general-setting/creditor-profile/${creditorType}`
              )
            : history.push(
                `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}`
              )
        }
        smTitle={'Accounts Payable'}
        title={user?.accountName}
        routeSegments={[
          { name: 'Accounts Payable' },
          { name: 'Main Menu' },
          { name: 'Creditor Profile', current: true },
        ]}
      />
      <ContentWrapper float>
        <CardContents
          section={{
            header: {
              title:
                page === 'creditor-profile'
                  ? `${creditorName(creditorType)} Details`
                  : `${lowerCaseAllWordsExceptFirstLetters(
                      getCreditorAccount[0]?.CreditorTypeEnum
                    )} Profile`,
              onClickAction: () =>
                page === 'creditor-profile'
                  ? history.push({
                      pathname: `/account-payable/general-setting/creditor-profile/${creditorType}/${CreditorAccountID}/edit`,
                      state: getCreditorAccount[0],
                    })
                  : history.push({
                      pathname: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/edit`,
                      state: getCreditorAccount[0],
                    }),
              icon: (
                <CreateIcon
                  style={{
                    color: handlePermDisabled({
                      permEnum: AcctPermission.AccPayableCreditorProfileUpdate,
                    })
                      ? 'grey'
                      : '#FF9800',
                  }}
                />
              ),
              disabled: handlePermDisabled({
                permEnum: AcctPermission.AccPayableCreditorProfileUpdate,
              }),
            },
          }}
        >
          <div className="content-wrap full">
            <div className="desc c-grey">Type</div>
            <div className="mdDesc">
              {lowerCaseAllWordsExceptFirstLetters(
                getCreditorAccount?.[0]?.einvoice_type
              )}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc c-grey">Creditor Type</div>
            <div className="mdDesc">
              {page === 'creditor-profile'
                ? `${creditorName(creditorType)} - ${
                    getCreditorAccount[0]?.CreditorType?.Description
                  }`
                : `${lowerCaseAllWordsExceptFirstLetters(
                    getCreditorAccount[0]?.CreditorTypeEnum
                  )} - ${getCreditorAccount[0]?.CreditorType?.Description}`}
            </div>
          </div>
          <div className="content-wrap left">
            <div className="desc c-grey">Company Registration No.</div>
            <div className="mdDesc">{getCreditorAccount[0]?.CompanyRegNo}</div>
          </div>

          <div className="content-wrap full">
            <div className="desc c-grey">Company Name</div>
            <div className="mdDesc">{getCreditorAccount[0]?.CompanyName}</div>
          </div>

          <div className="content-wrap full">
            <div className="desc c-grey">Phone No</div>
            <div className="mdDesc">+{getCreditorAccount[0]?.ContactNo}</div>
          </div>

          <div className="content-wrap full">
            <div className="desc c-grey">Email Address</div>
            <div className="mdDesc">{getCreditorAccount[0]?.Email ?? 'NA'}</div>
          </div>

          <div className="content-wrap left">
            <div className="desc c-grey">Credit Terms</div>
            <div className="mdDesc">{getCreditorAccount[0]?.CreditTerm}</div>
          </div>

          {/* <div className="content-wrap right">
            <div className="desc c-grey">Tax</div>
            <div className="mdDesc">
              {getCreditorAccount[0]?.TaxScheme?.Code}
            </div>
          </div> */}

          <div className="content-wrap left">
            <div className="desc c-grey">Region</div>
            <div className="mdDesc">
              {getCreditorAccount[0]?.Region ?? 'NA'}
            </div>
          </div>

          <div className="content-wrap right">
            <div className="desc c-grey">SST Registration No.</div>
            <div className="mdDesc">
              {getCreditorAccount[0]?.SSTRegNo ?? 'NA'}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc c-grey">GST Registration No.</div>
            <div className="mdDesc">
              {getCreditorAccount[0]?.GSTRegNo ?? 'NA'}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc c-grey">Remark</div>
            <div className="mdDesc">
              {getCreditorAccount[0]?.Remark ?? 'NA'}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc c-grey">Bank Account No.</div>
            <div className="mdDesc">
              {getCreditorAccount[0]?.BankAccountNo ?? 'NA'}
            </div>
          </div>

          <div className="content-wrap right">
            <div className="desc c-grey">Bank Name</div>
            <div className="mdDesc">
              {getCreditorAccount[0]?.BankName ?? 'NA'}
            </div>
          </div>
          <div className="content-wrap left">
            <div className="desc c-grey">SWIFT Code</div>
            <div className="mdDesc">
              {getCreditorAccount[0]?.SWIFTCode ?? 'NA'}
            </div>
          </div>

          <div className="content-wrap right">
            <div className="desc c-grey">Payee Name</div>
            <div className="mdDesc">
              {getCreditorAccount[0]?.PayeeName ?? 'NA'}
            </div>
          </div>
        </CardContents>
        {eInvoiceSubscriber && (
          <CardContents
            section={{
              header: {
                title: 'e-Invoice Details',
                onClickAction: () =>
                  page === 'creditor-profile'
                    ? history.push({
                        pathname: `/account-payable/general-setting/creditor-profile/${creditorType}/${CreditorAccountID}/edit`,
                        state: getCreditorAccount[0],
                      })
                    : history.push({
                        pathname: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/edit`,
                        state: getCreditorAccount[0],
                      }),
                icon: (
                  <CreateIcon
                    style={{
                      color: handlePermDisabled({
                        permEnum:
                          AcctPermission.AccPayableCreditorProfileUpdate,
                      })
                        ? 'grey'
                        : '#FF9800',
                    }}
                  />
                ),
                disabled: handlePermDisabled({
                  permEnum: AcctPermission.AccPayableCreditorProfileUpdate,
                }),
              },
            }}
          >
            <div className="content-wrap left">
              <div className="desc c-grey">Self-Billed</div>
              <div className="mdDesc">
                {getCreditorAccount[0]?.einvoice_self_billed ? 'Yes' : 'No'}
              </div>
            </div>
            <div className="content-wrap right">
              <div className="desc c-grey">Send e-Invoice Individually</div>
              <div className="mdDesc">
                {getCreditorAccount[0]?.einvoice_send_individually
                  ? 'Yes'
                  : 'No'}
              </div>
            </div>

            <div className="content-wrap right">
              <div className="desc" style={{ color: '#afafaf' }}>
                TIN
              </div>
              <div className="" style={{ fontSize: '12px', fontWeight: 500 }}>
                {getCreditorAccount[0]?.einvoice_TIN ?? 'NA'}
              </div>
            </div>

            <div className="content-wrap full">
              <div className="desc c-grey">Classification Code</div>
              <div className="mdDesc">
                {getCreditorAccount[0]?.ClassificationCode ? (
                  <span>
                    {`${getCreditorAccount[0]?.ClassificationCode?.classificationCode}(
                ${getCreditorAccount[0]?.ClassificationCode?.description})`}
                  </span>
                ) : (
                  'NA'
                )}
              </div>
            </div>

            <div className="content-wrap full">
              <div className="desc c-grey">MSIC Code</div>
              <div className="mdDesc">
                {getCreditorAccount[0]?.MSICCode ? (
                  <span>{`${getCreditorAccount[0]?.MSICCode?.msicCode}(${getCreditorAccount[0]?.MSICCode?.description})`}</span>
                ) : (
                  'NA'
                )}
              </div>
            </div>
          </CardContents>
        )}
        <CardContents
          section={{
            header: {
              title: 'Address Info',
              onClickAction: () =>
                page === 'creditor-profile'
                  ? history.push({
                      pathname: `/account-payable/general-setting/creditor-profile/${creditorType}/${CreditorAccountID}/edit`,
                      state: getCreditorAccount[0],
                    })
                  : history.push({
                      pathname: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/edit`,
                      state: getCreditorAccount[0],
                    }),
              icon: (
                <CreateIcon
                  style={{
                    color: handlePermDisabled({
                      permEnum: AcctPermission.AccPayableCreditorProfileUpdate,
                    })
                      ? 'grey'
                      : '#FF9800',
                  }}
                />
              ),
              disabled: handlePermDisabled({
                permEnum: AcctPermission.AccPayableCreditorProfileUpdate,
              }),
            },
          }}
        >
          <div className="content-wrap full">
            <div className="desc c-grey">Address</div>
            <div className="mdDesc">
              {getCreditorAccount[0]?.Address?.address},{' '}
              {getCreditorAccount[0]?.Address?.postCode}{' '}
              {getCreditorAccount[0]?.Address?.city &&
                `${getCreditorAccount[0]?.Address?.city}, `}
              {getCreditorAccount[0]?.Address?.state &&
                `${getCreditorAccount[0]?.Address?.state}, `}
              {getCreditorAccount[0]?.Address?.country}
            </div>
          </div>
        </CardContents>
        <CardContents
          section={{
            header: {
              title: 'Contact Details',
              onClickAction: () =>
                page === 'creditor-profile'
                  ? history.push({
                      pathname: `/account-payable/general-setting/creditor-profile/${creditorType}/${CreditorAccountID}/edit`,
                      state: getCreditorAccount[0],
                    })
                  : history.push({
                      pathname: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/edit`,
                      state: getCreditorAccount[0],
                    }),
              icon: (
                <CreateIcon
                  style={{
                    color: handlePermDisabled({
                      permEnum:
                        AcctPermission.AccPayableGeneralSettingsCreditorProfileUpdate,
                    })
                      ? 'grey'
                      : '#FF9800',
                  }}
                />
              ),
              disabled: handlePermDisabled({
                permEnum:
                  AcctPermission.AccPayableGeneralSettingsCreditorProfileUpdate,
              }),
            },
          }}
        >
          {getCreditorAccount[0]?.CreditorContact?.length === 0 ? (
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EmptyList
                title="No Contact Record(s)"
                subtitle="Click on edit button to add records"
              />
            </div>
          ) : (
            getCreditorAccount[0]?.CreditorContact?.map((el, index) => {
              return (
                <List style={{ width: '100%', paddingBottom: '0px' }}>
                  <ListItem
                    key={index}
                    style={{ marginLeft: '-5px', marginTop: '-15px' }}
                  >
                    <ListItemText
                      primary={
                        <span className="desc xsTitle">
                          {el.Name} ({el.Designation})
                        </span>
                      }
                      secondary={
                        <span className="desc">
                          <span>
                            <PhoneIcon
                              style={{
                                fontSize: '10px',
                                color: 'black',
                                marginBottom: '-1px',
                                marginRight: '3px',
                              }}
                            />

                            <span
                              className={` primary-click-text`}
                              onClick={e => {
                                window.open(`tel:${el.ContactNo}`)
                              }}
                            >
                              +{el.ContactNo}
                            </span>
                          </span>
                          {` | `}
                          <span>
                            <EmailIcon
                              style={{
                                fontSize: '12px',
                                color: 'black',
                                marginBottom: '-3px',
                                marginRight: '3px',
                              }}
                            />
                            <span
                              className={` primary-click-text`}
                              onClick={e => {
                                window.open(`mailto:${el.Email}`)
                              }}
                            >
                              {el.Email}
                            </span>
                          </span>
                        </span>
                      }
                    />
                  </ListItem>
                </List>
              )
            })
          )}
        </CardContents>
        <CardContents
          section={{
            header: {
              title: 'Director Details',
              onClickAction: () =>
                page === 'creditor-profile'
                  ? history.push({
                      pathname: `/account-payable/general-setting/creditor-profile/${creditorType}/${CreditorAccountID}/edit`,
                      state: getCreditorAccount[0],
                    })
                  : history.push({
                      pathname: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/edit`,
                      state: getCreditorAccount[0],
                    }),
              icon: (
                <CreateIcon
                  style={{
                    color: handlePermDisabled({
                      permEnum:
                        AcctPermission.AccPayableGeneralSettingsCreditorProfileUpdate,
                    })
                      ? 'grey'
                      : '#FF9800',
                  }}
                />
              ),
              disabled: handlePermDisabled({
                permEnum:
                  AcctPermission.AccPayableGeneralSettingsCreditorProfileUpdate,
              }),
            },
          }}
        >
          {getCreditorAccount[0]?.CreditorDirector?.length === 0 ? (
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EmptyList
                title="No Director Record(s)"
                subtitle="Click on edit button to add records"
              />
            </div>
          ) : (
            getCreditorAccount[0]?.CreditorDirector?.map((el, index) => {
              return (
                <List style={{ width: '100%', paddingBottom: '0px' }}>
                  <ListItem
                    key={index}
                    style={{ marginLeft: '-5px', marginTop: '-15px' }}
                  >
                    <ListItemText
                      primary={
                        <>
                          <span className="desc">
                            <span className="xsTitle">{el.Name}</span>
                          </span>
                        </>
                      }
                      secondary={
                        <span className="desc">
                          <span className="desc">
                            <span>
                              <PhoneIcon
                                style={{
                                  fontSize: '10px',
                                  color: 'black',
                                  marginBottom: '-1px',
                                  marginRight: '3px',
                                }}
                              />

                              <span
                                className={` primary-click-text`}
                                onClick={e => {
                                  window.open(`tel:${el.ContactNo}`)
                                }}
                              >
                                +{el.ContactNo}
                              </span>
                            </span>
                            {` | `}
                            <span>
                              <EmailIcon
                                style={{
                                  fontSize: '12px',
                                  color: 'black',
                                  marginBottom: '-3px',
                                  marginRight: '3px',
                                }}
                              />
                              <span
                                className={` primary-click-text`}
                                onClick={e => {
                                  window.open(`mailto:${el.Email}`)
                                }}
                              >
                                {el.Email}
                              </span>
                            </span>
                          </span>
                        </span>
                      }
                    />
                  </ListItem>
                </List>
              )
            })
          )}
        </CardContents>
      </ContentWrapper>
      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
