import { dateFormat } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'

export const ELetterServiceCB = ({
  docType,
  previewDetail,
  paramVal,
  ItemIndex,
}) => {
  switch (docType) {
    case 'payment':
      return Object.assign(paramVal, {
        '[CompanyBankAccCode]': previewDetail?.BankAccount?.Code! ?? '',
        '[CompanyBankAccNo]': previewDetail?.BankAccount?.AccountNo! ?? '',
        '[ChequeNo]': previewDetail?.ChequeNo! ?? '',
        '[Description]': previewDetail?.Description! ?? '',
        '[DocumentNo]': previewDetail?.DocNo!,
        '[DocumentDate]': dateFormat(previewDetail?.DocDate!) ?? '',
        '[TransactionDate]': dateFormat(previewDetail?.TransactionDate!) ?? '',
        '[ReferenceNo]': previewDetail?.RefNo! ?? '',
        '[PayeeName]': previewDetail?.PayeeName! ?? '',
        '[PaymentMethod]': previewDetail?.PaymentMethod?.Name! ?? '',
        items: previewDetail?.PaymentItem?.map((el, i) => {
          return {
            '[Index]': ItemIndex + i + 1,
            '[ItemDocDate]': el?.DocDate ? dateFormat(el?.DocDate!) : '',
            '[ItemAccCode]': el?.MasterCOA?.Code!,
            '[ItemAccDesc]': el?.MasterCOA?.Name!,
            '[ItemDocNo]': el?.DocNo! ?? '',
            '[ItemRemark]': el?.Remark!,
            '[ItemTaxRate]': amtStr(el?.TaxRate!),
            '[ItemBaseAmt]': amtStr(el?.Amount!),
            '[ItemTaxAmt]': amtStr(el?.TaxAmt!),
            '[ItemTotalAmt]': amtStr(el?.DocAmt!),
          }
        }),
        '[SumTotalAmt]': amtStr(
          previewDetail?.PaymentItem?.reduce(
            (total, current) => (total = total + current?.DocAmt),
            0
          )
        ),
      })
    case 'receipt':
      return Object.assign(paramVal, {
        '[CompanyBankAccCode]': previewDetail?.BankAccount?.Code! ?? '',
        '[CompanyBankAccNo]': previewDetail?.BankAccount?.AccountNo! ?? '',
        '[ChequeNo]': previewDetail?.ChequeNo! ?? '',
        '[Description]': previewDetail?.Description! ?? '',
        '[DocumentNo]': previewDetail?.DocNo!,
        '[DocumentDate]': dateFormat(previewDetail?.DocDate!) ?? '',
        '[TransactionDate]': dateFormat(previewDetail?.TransactionDate!) ?? '',
        '[ReferenceNo]': previewDetail?.RefNo! ?? '',
        '[ReceivedFrom]': previewDetail?.ReceivedFrom! ?? '',
        '[PaymentMethod]': previewDetail?.PaymentMethod?.Name! ?? '',
        items: previewDetail?.ReceiptItem?.map((el, i) => {
          return {
            '[Index]': ItemIndex + i + 1,
            '[ItemDocDate]': el?.DocDate ? dateFormat(el?.DocDate!) : '',
            '[ItemRemark]': el?.Remark! ?? '',
            '[ItemAccCode]': el?.MasterCOA?.Code,
            '[ItemTaxRate]': amtStr(el?.TaxRate!),
            '[ItemAccDesc]': el?.MasterCOA?.Name!,
            '[ItemBaseAmt]': amtStr(el?.Amount!),
            '[ItemTaxAmt]': amtStr(el?.TaxAmt!),
            '[ItemTotalAmt]': amtStr(el?.DocAmt!),
          }
        }),
        '[SumTotalAmt]': amtStr(
          previewDetail?.ReceiptItem?.reduce(
            (total, current) => (total = total + current?.DocAmt),
            0
          )
        ),
      })
    case 'bank-transfer':
      Object.assign(paramVal, {
        '[DocumentNo]': previewDetail?.DocNo!,
        '[TransferDate]': dateFormat(previewDetail?.TransferDate!) ?? '',
        '[TransactionDate]': dateFormat(previewDetail?.TransactionDate!),
        '[ReferenceNo]': previewDetail?.RefNo! ?? '',
        '[ToBankAcc]': previewDetail?.ToBankAccountDetail?.BankName ?? '',
        '[FromBankAcc]': previewDetail?.FromBankAccountDetail?.BankName ?? '',
        '[ToBankAccNo]': previewDetail?.ToBankAccountDetail?.AccountNo ?? '',
        '[FromBankAccNo]':
          previewDetail?.FromBankAccountDetail?.AccountNo ?? '',
        '[PaymentMethod]': previewDetail?.PaymentMethod?.Name! ?? '',
        '[ChequeNo]': previewDetail?.ChequeNo! ?? '',
        '[Description]': previewDetail?.Description! ?? '',
        '[FromBankCode]': previewDetail?.FromBankAccountDetail?.Code! ?? '',
        '[ToBankCode]': previewDetail?.ToBankAccountDetail?.Code! ?? '',
        '[TotalAmt]': amtStr(previewDetail?.Amount!),
      })
  }
}
